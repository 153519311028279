<template>
  <wgu-module-card v-bind="$attrs"
      :moduleName="moduleName"
      class="dss-flooddamage-win"
      v-on:visibility-change="show"
      width=400>

      <!-- TODO: Remove hover tooltips, currently only for debugging. -->
      <!-- <dss-flooddamage-building-hover-tooltip/> -->
      <!-- <dss-flooddamage-beam-hover-tooltip/> -->
      <!-- <dss-flooddamage-inundation-hover-tooltip/> -->
      <dss-district-hover-tooltip/>

      <v-subheader>{{ $t('dss-flooddamage.scenario') }}</v-subheader>
      <v-card-text class="pt-0">
        <v-select
          color="secondary"
          item-color="secondary"
          v-model="scenarioId"
          :items="scenarios"
          item-text="title"
          item-value="id"
          prepend-icon="mdi-home-thermometer-outline"
          menu-props="auto"
          dense
          hide-details>
        </v-select>
      </v-card-text>

      <v-subheader>{{ $t('dss-flooddamage.model') }}</v-subheader>
      <v-card-text class="pt-0">
        <v-select
          color="secondary"
          item-color="secondary"
          v-model="damageModelId"
          :items="damageModels"
          item-text="label"
          item-value="id"
          prepend-icon="mdi-globe-model"
          menu-props="auto"
          dense
          hide-details>
        </v-select>
      </v-card-text>

      <v-card-actions>
        <v-row align="center" justify="center" no-gutters>
          <v-item-group class="mr-3">
            <v-container>
              <v-row>
                <v-col class="mr-1">
                  <v-item v-slot:default="{ active, toggle }">
                    <dss-areapicker
                      :tooltip="$t('dss-flooddamage.drawRegion')"
                      imageSrc="static/icon/search.png"
                      :imageScale=0.4
                      type="Polygon"
                      :isActive="active"
                      v-model="selectedArea"
                      @toggle="toggle"
                    />
                  </v-item>
                </v-col>
                <v-col>
                  <v-item v-slot:default="{ active, toggle }">
                    <dss-featurepicker
                      :tooltip="$t('dss-flooddamage.selectDistrict')"
                      layerId="city-districts-duisburg"
                      :isActive="active"
                      v-model="selectedDistricts"
                      @toggle="onDistrictToggle($event); toggle()"
                    />
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>

          <v-col class="px-2" align-self="center">
            <v-progress-linear
              color="secondary"
              :active="loading"
              buffer-value="0"
              stream
            ></v-progress-linear>
          </v-col>

          <v-col sm="12" md="12" lg="12">
            <v-alert
              v-model="alertVisible"
              :type="alertType"
              dismissible
              dense
              transition="scroll-y-transition"
              class="mt-2 mb-0"
              >
              {{ alertMessage }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-actions>

      <div v-if="damage.length">
        <v-subheader v-if="requestedArea">
          {{ $t('dss-flooddamage.areaResult') }}
        </v-subheader>
        <v-subheader v-if="requestedDistrict">
          {{ $t('dss-flooddamage.districtResult', [requestedDistrict.get('name_district')]) }}
        </v-subheader>

        <dss-flooddamage-barchart
          :dataSource="barChartDataSource"
          seriesColumn="asset_type_descr"
          labelColumn="asset_kind_descr"
          valueColumn="dmg_total_mio"
          :colors="chartColors"
          :loading="loading"
          :group="chartGroup"/>
        <!-- TODO: Remove pie chart entirely, currently uncommented for future purpose. -->
        <!-- <dss-flooddamage-piechart
          :dataSource="pieChartDataSource"
          labelColumn="asset_type_descr"
          valueColumn="dmg_total_mio"
          :colors="chartColors"
          :loading="loading"
          :group="chartGroup"/> -->
      </div>
  </wgu-module-card>
</template>

<script>
import ModuleCard from '@Wegue/components/modulecore/ModuleCard';
import { Mapable } from '@Wegue/mixins/Mapable';
// import PieChart from './PieChart'
import BarChart from './BarChart'
// import DamageBuildingHoverTooltip from './DamageBuildingHoverTooltip'
// import DamageBEAMHoverTooltip from './DamageBEAMHoverTooltip'
// import InundationHoverTooltip from './InundationHoverTooltip'
import DistrictHoverTooltip from './DistrictHoverTooltip'
import DamageBuildingController from './DamageBuildingController'
import DamageBEAMController from './DamageBEAMController'
import AreaPicker from '../uicontrols/areaPicker/AreaPicker';
import FeaturePicker from '../uicontrols/featurePicker/FeaturePicker';
import WFSRequest from '../../util/WFSRequest';
import axios from 'axios';
import {
  connect
} from 'echarts/core';
export default {
  name: 'dss-flooddamage-win',
  inheritAttrs: false,
  mixins: [Mapable],
  components: {
    'wgu-module-card': ModuleCard,
    // 'dss-flooddamage-piechart': PieChart,
    'dss-flooddamage-barchart': BarChart,
    // 'dss-flooddamage-building-hover-tooltip': DamageBuildingHoverTooltip,
    // 'dss-flooddamage-beam-hover-tooltip': DamageBEAMHoverTooltip,
    // 'dss-flooddamage-inundation-hover-tooltip': InundationHoverTooltip,
    'dss-district-hover-tooltip': DistrictHoverTooltip,
    'dss-areapicker': AreaPicker,
    'dss-featurepicker': FeaturePicker
  },
  props: {
    /**
       * WFS/WMS parameters, such as the destination URL and projection for geometries.
       */
    services: { type: Object, required: true }
  },
  data () {
    return {
      moduleName: 'dss-flooddamage',
      /**
         * The selected damage model id.
         */
      damageModelId: undefined,
      /**
         * The list of available damage models. Corresponds to damage controllers.
         */
      damageModels: [],
      /**
         * The active controller for WMS / WFS requests.
         */
      damageController: undefined,
      /**
         * Controllers for WMS / WFS requests.
         */
      damageControllers: [],
      /**
        * The selected scenario id.
        */
      scenarioId: undefined,
      /**
         * The selected scenario record.
         */
      scenario: undefined,
      /**
         * The list of available scenarios.
         */
      scenarios: [],
      /**
         * A geometry containing the selected area on map.
         */
      selectedArea: undefined,
      /*
         * A geometry containing the recently requested area.
         * This has to be stored separetely from selectedArea,
         * in case of a selection reset.
         */
      requestedArea: undefined,
      /**
         * The features corresponding to the selected districts.
         */
      selectedDistricts: undefined,
      /**
         * The feature containing the recently request district.
         * This has to be stored separetely from selectedDistricts,
         * in case of a selection reset.
         */
      requestedDistrict: undefined,
      /**
         * Object containing the computed damage.
         */
      damage: [],
      /**
         * Computing state.
         */
      loading: false,
      /**
         * List of errors / warnings
         */
      alertTypes: {
        requestError: 'error',
        noDataWarning: 'warning'
      },
      /**
         * Binding for visibility of the alert box.
         */
      alertVisible: false,
      /**
         * Currently displayed error / warning message.
         */
      alertCode: null,
      /**
         * Cancel token source to cancel pending requests.
         */
      pendingRequestsCancelSrc: null,
      /**
         * Display names for the asset types enumeration.
         */
      assetTypes: {},
      /**
         * Display names for the asset kinds enumeration.
         */
      assetKinds: {},
      /**
         * Chart group ID for connecting charts.
         */
      chartGroup: 'dss-flooddamage-charts'
    }
  },
  methods: {
    /**
       * This function is executed, after the map is bound (see mixins/Mapable).
       * Initialize the flood damage module.
       */
    onMapBound () {
      this.requestScenarios();
      this.createControllers();
      this.getAssetTypes();
      this.getAssetKinds();
      this.getDamageModels(true);
      this.connectCharts();
    },
    /**
       * Loads module specific layers when the module is opened / closed.
       * @param  {boolean} visible New visibility state
       */
    show (visible) {
      if (visible) {
        this.createLayers();
      } else {
        this.removeLayers();
      }
    },
    /**
       * Connect the charts to be manageble via the same legend.
       */
    connectCharts () {
      connect(this.chartGroup);
    },
    /**
       * Create controllers for WMS / WFS requests.
       */
    createControllers () {
      const baseService = this.services.damageBEAM;
      this.damageControllers = [
        new DamageBuildingController(this.map, this.services.damageBuilding, baseService),
        new DamageBEAMController(this.map, this.services.damageBEAM, baseService)
      ];
      this.damageController = this.damageControllers[0];
    },
    /**
       * Initialize the damage model enumeration.
       * @param  {boolean} init Initially set the default selection.
       */
    getDamageModels (init) {
      this.damageModels = [
        {
          id: 0,
          label: this.$t('dss-flooddamage.modelBuilding')
        },
        {
          id: 1,
          label: this.$t('dss-flooddamage.modelBEAM')
        }
      ];
      if (init) {
        this.damageModelId = this.damageModels[0].id;
      }
    },
    /**
       * Initialize display names for the asset types enumeration.
       * Currently this enumeration is shared between building and BEAM damage
       * model, while the building model only uses values 1-4.
       */
    getAssetTypes () {
      this.assetTypes = {
        1: this.$t('dss-flooddamage.assetTypePrivate'),
        2: this.$t('dss-flooddamage.assetTypeIndustry'),
        3: this.$t('dss-flooddamage.assetTypeService'),
        4: this.$t('dss-flooddamage.assetTypeAgriculture'),
        5: this.$t('dss-flooddamage.assetTypeMixed'),
        6: this.$t('dss-flooddamage.assetTypeTransport')
      }
    },
    /**
       * Initialize display names for the asset kinds enumeration.
       * Currently this enumeration is shared between building and BEAM damage
       * model, while the building model only uses values 1-4.
       */
    getAssetKinds () {
      this.assetKinds = {
        1: this.$t('dss-flooddamage.assetKindBuilding'),
        2: this.$t('dss-flooddamage.assetKindHousehold'),
        3: this.$t('dss-flooddamage.assetKindSupply'),
        4: this.$t('dss-flooddamage.assetKindVehicle'),
        5: this.$t('dss-flooddamage.assetKindFixedAsset'),
        6: this.$t('dss-flooddamage.assetKindLivestock'),
        7: this.$t('dss-flooddamage.assetKindRoads'),
        8: this.$t('dss-flooddamage.assetKindRails'),
        9: this.$t('dss-flooddamage.assetKindAgriculture'),
        10: this.$t('dss-flooddamage.assetKindGrassland'),
        11: this.$t('dss-flooddamage.assetKindForest'),
        12: this.$t('dss-flooddamage.assetKindSports')
      }
    },
    /**
       * Request the list of available scenarios.
       */
    requestScenarios () {
      const me = this;

      WFSRequest.send(this.services.scenario.baseUrl + '/ows', {
        featureTypes: ['v_flood_damage'],
        outputFormat: 'application/json'
      })
        .then(function (response) {
          const features = response.data.features;
          me.scenarios = features
            .map(feat => ({
              id: parseInt(feat.id.split('.')[1]),
              ...feat.properties
            }))
            .sort((a, b) => {
              return a.id - b.id;
            });
          if (me.scenarios?.length > 0) {
            me.scenarioId = me.scenarios[0].id;
          }
        })
        .catch(function (error) {
          me.alertCode = 'requestError';
          console.error(error);
        });
    },
    /**
       * Request the total damage for the currently selected damage controller,
       * scenario and area or district.
       */
    requestDamage () {
      const me = this;
      let promise;
      const cancelToken = axios.CancelToken;

      // Cancel pending requests and create a new cancel token source which corresponds
      // to the async request sent in this iteration.
      if (this.pendingRequestsCancelSrc) {
        this.pendingRequestsCancelSrc.cancel();
      }
      this.pendingRequestsCancelSrc = cancelToken.source();

      // Send the request.
      if (this.requestedArea) {
        promise = this.damageController.requestAreaDamage(
          this.scenario, this.requestedArea, this.pendingRequestsCancelSrc);
      } else if (this.requestedDistrict) {
        promise = this.damageController.requestDistrictDamage(
          this.scenario, this.requestedDistrict, this.pendingRequestsCancelSrc);
      }

      if (promise) {
        me.loading = true;
        me.alertVisible = false;
        promise
          .then(damage => {
            me.loading = false;
            me.damage = damage;
            me.alertCode =
                (damage?.length > 0) ? false : 'noDataWarning';
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              me.loading = false;
              me.alertCode = 'requestError';
              console.error(error);
            }
          });
      }
    },
    /**
       * If the district selection is activated, clear out the existing
       * area selection.
       * @param {boolean} active Whether the district selection is active.
       */

    onDistrictToggle (active) {
      if (active) {
        this.selectedArea = null;
      }
    },
    /**
       * Create module specific layers when the module is opened.
       */
    createLayers () {
      // TODO:
      //  Initilization will fail when the module is initially
      //  visible, because scenarios have not been requested yet.
      if (this.scenario) {
        this.damageController.createMapLayer(this.scenario);
      }
    },
    /**
       * Remove module specific layers when the module is closed.
       */
    removeLayers () {
      this.damageController.removeMapLayer();
    }
  },
  computed: {
    /**
       * Returns tabular data to be displayed in the pie chart.
       * This transforms the data model to tabular data grouped by asset type.
       * For the naming of columns, the display names are used as provided by
       * assetTypes and assetKinds enumerations.
       */
    // pieChartDataSource () {
    //   const result = [];
    //   this.damage.reduce((acc, row) => {
    //     const group = row.asset_type_id;
    //     if (!acc[group]) {
    //       acc[group] = {
    //         asset_type_descr: this.assetTypes[row.asset_type_id],
    //         dmg_total_mio: 0
    //       }
    //       result.push(acc[group])
    //     }
    //     acc[group].dmg_total_mio += row.dmg_total_mio;
    //     return acc;
    //   }, {});
    //   return result;
    // },
    /*
       * Returns tabular data to be displayed in the bar chart.
       * This contains one row per assetType / assetKind combination.
       * The original dataset from the controllers is only altered to have
       * localized display names for assetTypes and assetKinds.
       */
    barChartDataSource () {
      const result = this.damage.map((row) => (
        {
          ...row,
          asset_type_descr: this.assetTypes[row.asset_type_id],
          asset_kind_descr: this.assetKinds[row.asset_kind_id]
        }
      ));
      return result;
    },
    /**
       * Returns an array of colors to be used in the chart.
       * This enforces a fixed mapping between asset types and colors.
       */
    chartColors () {
      const colors = [null, '#73c0de', '#3ba272', '#9a60b4', '#ea7ccc', '#ffdb5c', '#ff9f7f'];
      const uniqueTypes = [...new Set(this.damage.map(row => row.asset_type_id))];
      return uniqueTypes.map(t => colors[t]);
    },
    /**
       * Return a localized error / warning message associated with the
       * current error code.
       */
    alertMessage () {
      return this.alertVisible
        ? this.$t(`dss-flooddamage.alert.${this.alertCode}`)
        : '';
    },
    /**
       * Return the type of alert associated with the current error code.
       */
    alertType () {
      return this.alertTypes[this.alertCode];
    }
  },

  watch: {
    /**
       * Watch for damage model selection change.
       */
    damageModelId (newVal, oldVal) {
      const oldController = this.damageControllers[oldVal];
      if (oldController) {
        oldController.removeMapLayer();
      }
      this.damageController = this.damageControllers[newVal];
      if (this.scenario) {
        this.damageController.createMapLayer(this.scenario);
      }

      this.requestDamage();
    },
    /**
       * Watch for scenarioId selection change.
       */
    scenarioId (newVal) {
      if (!this.damageController) {
        return;
      }

      this.scenario = this.scenarios.find(s => s.id === newVal);
      this.damageController.updateMapLayer(this.scenario);
      this.requestDamage();
    },
    /**
       * Request the damage after an area selection has been completed.
       */
    selectedArea () {
      if (this.selectedArea) {
        this.requestedArea = this.selectedArea;
        this.requestedDistrict = null;
        this.requestDamage();
      }
    },
    /**
       * Request the damage after a city district selection has been completed.
       */
    selectedDistricts () {
      const feature = this.selectedDistricts?.length
        ? this.selectedDistricts[0]
        : null;
      if (feature) {
        this.requestedDistrict = feature;
        this.requestedArea = null;
        this.requestDamage();
      }
    },
    /**
       * Watch for an alert code to be set to display an alert message.
       */
    alertCode () {
      this.alertVisible = !!this.alertCode;
    },
    /**
       * If the user dismisses an alert, reset the alert code,
       * so it can be displayed on the next occasion again.
       */
    alertVisible () {
      if (!this.alertVisible) {
        this.alertCode = null;
      }
    },
    /**
       * Watch for locale changes.
       */
    '$i18n.locale': function () {
      this.getAssetTypes();
      this.getAssetKinds();
      this.getDamageModels();
    }
  }
}
</script>
